import ApiService from '@/services/ApiService';

const API_URL = '/api/Customer/PlayroomRating/';

class CustomerPlayroomRatingService {
  forDetail(orderId: string) {
    return ApiService.get(`${API_URL}Detail?orderId=${orderId}`);
  }

  allDetail(orderId: string) {
    return ApiService.get(`${API_URL}AllDetail?orderId=${orderId}`);
  }

  getIndex(currentPage: number, sortColumn: string, sortAsc: boolean, tableFilter: string) {
    return ApiService.get(
      `${API_URL}Index?currentPage=${currentPage}&sortColumn=${sortColumn}&sortAsc=${sortAsc}&tableFilter=${tableFilter}`
    );
  }

  Create(
    rating: {
      rating: string | number;
      cleanlinessRating: string | number;
      comfortRating: string | number;
      equipmentRating: string | number;
      locationRating: string | number;
      hostRating: string | number;
      priceRating: string | number;
    },
    orderId: string
  ) {
    rating.rating = String(rating.rating);
    rating.cleanlinessRating = String(rating.cleanlinessRating);
    rating.comfortRating = String(rating.comfortRating);
    rating.equipmentRating = String(rating.equipmentRating);
    rating.locationRating = String(rating.locationRating);
    rating.hostRating = String(rating.hostRating);
    rating.priceRating = String(rating.priceRating);
    return ApiService.post(`${API_URL}Create`, { ...rating, orderId: orderId });
  }

  forCreate(orderId: string) {
    return ApiService.get(`${API_URL}Create?orderId=${orderId}`);
  }
}

export default new CustomerPlayroomRatingService();
