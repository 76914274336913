



































































































import Vue from 'vue';
import ValidatedInput from '@/components/forms/ValidatedInput.vue';
import ValidatedTextArea from '@/components/forms/ValidatedTextArea.vue';
import { validationMixin } from 'vuelidate';
import CustomerPlayroomRatingService from '@/services/customers/CustomerPlayroomRatingService';
import Rating from '@/components/playrooms/Rating.vue';
import PlayroomRating from '@/models/PlayroomRating';

const validations = {
  playroomRating: {
    orderId: {},
    playroomId: {},
    priceRating: {},
    cleanlinessRating: {},
    comfortRating: {},
    equipmentRating: {},
    locationRating: {},
    hostRating: {},
    rating: {},
    subject: {},
    message: {},
  },
};
export default Vue.extend({
  components: { ValidatedInput, ValidatedTextArea, Rating },
  mixins: [validationMixin],
  validations,
  props: {
    playroomId: { type: String, required: true },
    orderId: { type: String, required: true },
    title: {
      type: String,
      required: true,
    },
    bookingSlot: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      playroomRating: {
        orderId: this.orderId,
        playroomId: this.playroomId,
        priceRating: 0,
        cleanlinessRating: 0,
        comfortRating: 0,
        equipmentRating: 0,
        locationRating: 0,
        hostRating: 0,
        rating: 0,
        subject: '',
        message: '',
      } as PlayroomRating,
    };
  },
  watch: {
    show() {
      if (this.show == true) {
        this.dataLoad();
      }
    },
  },
  methods: {
    dataLoad() {
      this.playroomRating.orderId = this.orderId;
      this.playroomRating.playroomId = this.playroomId;
      CustomerPlayroomRatingService.allDetail(this.playroomRating.orderId).then((res) => {
        this.playroomRating = res.value;
      });
    },
  },
});
