











































































































import Vue from 'vue';
import ValidatedInput from '@/components/forms/ValidatedInput.vue';
import ValidatedTextArea from '@/components/forms/ValidatedTextArea.vue';
import CustomerPlayroomRatingService from '@/services/customers/CustomerPlayroomRatingService';
import { validationMixin } from 'vuelidate';
import { maxLength } from 'vuelidate/lib/validators';
import SetRating from '@/components/playrooms/SetRating.vue';
import Rating from '@/components/playrooms/Rating.vue';
import Confirmation from '@/components/shared/Confirmation.vue';
import PlayroomRating from '@/models/PlayroomRating';
import Notification from '@/components/shared/Notification.vue';

const validations = {
  playroomRating: {
    orderId: {},
    playroomId: {},
    priceRating: {},
    cleanlinessRating: {},
    comfortRating: {},
    equipmentRating: {},
    locationRating: {},
    hostRating: {},
    rating: {},
    subject: {
      maxLength: maxLength(50),
    },
    message: {
      maxLength: maxLength(500),
    },
  },
};

export default Vue.extend({
  components: { ValidatedInput, ValidatedTextArea, SetRating, Rating, Confirmation, Notification },
  mixins: [validationMixin],
  validations,
  props: {
    playroomId: { type: String, required: true },
    orderId: { type: String, required: true },
    title: {
      type: String,
      required: true,
    },
    bookingSlot: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      playroomRating: {
        orderId: this.orderId,
        playroomId: this.playroomId,
        priceRating: 0,
        cleanlinessRating: 0,
        comfortRating: 0,
        equipmentRating: 0,
        locationRating: 0,
        hostRating: 0,
        rating: 0,
        subject: '',
        message: '',
      } as PlayroomRating,
      showConfirmationModel: false,
      modeltitle: '',
      modelText: '',
      modelOkLabel: '',
      modelCancelLabel: '',
      showNotification: false,
    };
  },
  watch: {
    show() {
      if (this.show == true) {
        this.setDefaultData();
      }
    },
  },
  methods: {
    openConfirmation() {
      (this.modeltitle = this.$t('pages.customer.orders.customerOrderIndex.ratingTitle').toString()),
        (this.modelText = this.$t('pages.customer.orders.customerOrderIndex.ratingText').toString()),
        (this.modelOkLabel = this.$t('pages.customer.orders.customerOrderIndex.ratingOkLabel').toString()),
        (this.modelCancelLabel = this.$t('pages.customer.orders.customerOrderIndex.ratingCancelLabel').toString()),
        (this.showConfirmationModel = true);
    },
    save() {
      this.playroomRating.orderId = this.orderId;
      this.playroomRating.playroomId = this.playroomId;
      CustomerPlayroomRatingService.Create({ ...this.playroomRating }, this.playroomRating.orderId).then(
        () => {
          this.showConfirmationModel = false;
          this.showNotification = true;
        },
        () => {
          this.showConfirmationModel = false;
          this.$emit('onClose');
        }
      );
    },
    avgRating() {
      this.playroomRating.rating = Math.round(
        (this.playroomRating.priceRating +
          this.playroomRating.cleanlinessRating +
          this.playroomRating.comfortRating +
          this.playroomRating.equipmentRating +
          this.playroomRating.locationRating +
          this.playroomRating.hostRating) /
          6
      );
    },
    handleCloseNotification() {
      this.showNotification = false;
      this.$emit('onSubmit');
    },
    setDefaultData() {
      this.playroomRating = {
        orderId: this.orderId,
        playroomId: this.playroomId,
        priceRating: 0,
        cleanlinessRating: 0,
        comfortRating: 0,
        equipmentRating: 0,
        locationRating: 0,
        hostRating: 0,
        rating: 0,
        subject: '',
        message: '',
      };
    },
  },
});
