

































































































































































































import Vue, { VueConstructor } from 'vue';
import CustomerOrderService from '@/services/customers/CustomerOrderService';
import OrderStatus from '@/components/orders/OrderStatus.vue';
import ToastMessage from '@/models/ToastMessage';
import DataTable from '@/components/shared/DataTable.vue';
import Rating from '@/components/playrooms/Rating.vue';
import SupportModal from '@/components/shared/SupportModal.vue';
import DeleteConfirmationModal from '@/components/messages/DeleteConfirmationModal.vue';
import FormaterMixin from '@/mixins/FormaterMixin.vue';
import SetRatingModel from '@/components/playrooms/SetRatingModel.vue';
import ShowRatingModel from '@/components/playrooms/ShowRatingModel.vue';
import { MetaInfo } from 'vue-meta';

class CustomerOrderIndexDto {
  id!: string;
  playroomId!: string;
  playroomName!: string;
  bookingId!: string;
  status!: string;
  bookingType!: string;
  start!: Date;
  end!: Date;
  ownerDisplayName!: string;
  amount!: number;
  rating!: number | null;
  ownerId!: string;
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof FormaterMixin>>).extend({
  mixins: [FormaterMixin],
  components: {
    OrderStatus,
    DataTable,
    Rating,
    SupportModal,
    DeleteConfirmationModal,
    SetRatingModel,
    ShowRatingModel,
  },
  metaInfo(): MetaInfo {
    return {
      title: this.$t('pages.guest.GuestOrderIndex.meta.title').toString(),
    };
  },
  data() {
    return {
      orders: [] as CustomerOrderIndexDto[],
      tableFilterValue: '',
      pages: 0,
      sorterValue: { column: 'start', asc: false },
      currentPage: 1,
      loading: false,
      orderIndexToDelete: -1,
      showCancelModal: false,
      showDeleteModal: false,
      showSupportModal: false,
      showSetRatingModal: false,
      showShowRatingModal: false,
      playroomId: '',
      orderId: '',
      ratingTitle: '',
      bookingSlot: '',
    };
  },
  watch: {
    sorterValue: function () {
      this.loadData();
    },
    currentPage: function () {
      this.loadData();
    },
    tableFilterValue: function () {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
    this.setBreadCrumb();
  },
  methods: {
    openDeleteModal(index: number) {
      this.orderIndexToDelete = index;
      this.showDeleteModal = true;
    },
    closeDeleteModal() {
      this.orderIndexToDelete = -1;
      this.showDeleteModal = false;
    },
    internalOnDelete() {
      this.loading = true;
      const order = this.orders[this.orderIndexToDelete];
      CustomerOrderService.deleteOrder(order.id).then(
        () => {
          this.$store.commit(
            'addToastMessage',
            new ToastMessage(this.$t('components.hostOrderDetailButtons.successMsg').toString(), 'bg-success')
          );
          this.loading = false;
          this.closeDeleteModal();
          this.loadData();
        },
        (error) => {
          this.$store.commit(
            'addToastMessage',
            new ToastMessage(
              this.$t('components.hostOrderDetailButtons.errorMsg').toString() + error.message,
              'bg-warning'
            )
          );
          this.loading = false;
          this.closeDeleteModal();
        }
      );
    },
    openSupportModal() {
      this.showSupportModal = true;
    },
    closeSupportModal() {
      this.showSupportModal = false;
    },

    downloadInvoice(order: CustomerOrderIndexDto) {
      CustomerOrderService.getInvoice(order.id).then((res) => {
        var link = document.createElement('a');
        link.innerHTML = 'Download PDF file';
        link.download = 'Rechnung.pdf';
        link.href = 'data:application/octet-stream;base64,' + res.value;
        link.click();
      });
    },

    isOrderConfirmed(order: CustomerOrderIndexDto) {
      return order.status == 'Confirmed';
    },

    canBeDeleted(order: CustomerOrderIndexDto) {
      const orderStatusThatCanBeDeleted = ['Completed', 'Cancelled', 'Expired', 'Rejected'];
      return orderStatusThatCanBeDeleted.indexOf(order.status) != -1;
    },

    formatDate(date: any): string {
      return this.formatIsoDateTimeTz(date, 'Pp');
    },

    setBreadCrumb() {
      this.$store.commit('UiStoreModule/setBreadcrumbItems', [
        { translationKey: 'pages.guest.GuestOrderIndex.breadcrumb.last' },
      ]);
    },

    loadData() {
      this.loading = true;
      CustomerOrderService.getIndex(
        this.currentPage,
        this.sorterValue.column,
        this.sorterValue.asc,
        this.tableFilterValue
      ).then((res) => {
        this.loading = false;
        this.orders = res.value.orders.items;
        this.orders = this.orders.map((o) => ({ ...o, start: new Date(o.start), end: new Date(o.end) }));
        this.pages = res.value.orders.totalPages;
        this.setBreadCrumb();
      });
    },
    openSetRatingPopup(item: CustomerOrderIndexDto) {
      this.playroomId = item.playroomId;
      this.orderId = item.id;
      this.ratingTitle = item.playroomName;
      this.bookingSlot = `Check-In: ${this.formatDate(item.start)} | Check-Out: ${this.formatDate(item.end)}`;
      this.showSetRatingModal = true;
    },

    openShowRatingPopup(item: CustomerOrderIndexDto) {
      this.playroomId = item.playroomId;
      this.orderId = item.id;
      this.ratingTitle = item.playroomName;
      this.bookingSlot = `Check-In: ${this.formatDate(item.start)} | Check-Out: ${this.formatDate(item.end)}`;
      this.showShowRatingModal = true;
    },
    refreshData() {
      this.showSetRatingModal = false;
      this.loadData();
    },
  },
});
