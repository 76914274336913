












import Vue from 'vue';

export default Vue.extend({
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
    okLabel: {
      type: String,
      required: true,
    },
    cancelLabel: {
      type: String,
      required: true,
    },
  },
  methods: {
    onOk() {
      this.$emit('onOk');
    },
    onClose() {
      this.$emit('onClose');
    },
  },
});
