












































import Vue from 'vue';

export default Vue.extend({
  computed: {
    isDarkMode(): boolean {
      return this.$store.state.UiStoreModule.darkModeEnabled;
    },
  },
  methods: {
    image(full: boolean) {
      let color = '';
      if (this.isDarkMode) {
        color = '_white';
      }
      let status = 'empty';
      if (full) {
        status = 'full';
      }
      return '/images/icons/butt_' + status + color + '.png';
    },
    setRating(rating: number) {
      this.$emit('update:rating', rating);
    },
  },
  props: {
    rating: {
      required: true,
    },
    withZero: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: 32,
    },
  },
});
