var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DataTable',{attrs:{"items":_vm.orders,"fields":[
      {
        key: 'bookingId',
        label: _vm.$t('tables.customerOrders.bookingID'),
        sorter: false,
        filter: false,
      },
      {
        key: 'playroomName',
        label: _vm.$t('tables.customerOrders.playroomName'),
        sorter: false,
        filter: false,
      },
      {
        key: 'status',
        label: _vm.$t('tables.customerOrders.status'),
        sorter: false,
        filter: false,
      },
      {
        key: 'bookingType',
        label: _vm.$t('tables.customerOrders.bookingType'),
        sorter: false,
        filter: false,
      },
      {
        key: 'start',
        label: _vm.$t('tables.customerOrders.start'),
        sorter: false,
        filter: false,
      },
      {
        key: 'end',
        label: _vm.$t('tables.customerOrders.end'),
        sorter: false,
        filter: false,
      },
      {
        key: 'ownerDisplayName',
        label: _vm.$t('tables.customerOrders.hostName'),
        sorter: false,
        filter: false,
      },
      {
        key: 'amount',
        label: _vm.$t('tables.customerOrders.amount'),
        sorter: false,
        filter: false,
      },
      {
        key: 'rating',
        label: _vm.$t('tables.customerOrders.rating'),
        sorter: false,
        filter: false,
      },
      {
        key: 'actions',
        label: _vm.$t('tables.common.actions'),
        sorter: false,
        filter: false,
      } ],"table-filter-value":_vm.tableFilterValue,"sorter-value":_vm.sorterValue,"loading":_vm.loading},on:{"update:tableFilterValue":function($event){_vm.tableFilterValue=$event},"update:table-filter-value":function($event){_vm.tableFilterValue=$event},"update:sorterValue":function($event){_vm.sorterValue=$event},"update:sorter-value":function($event){_vm.sorterValue=$event}},scopedSlots:_vm._u([{key:"playroomName",fn:function(ref){
    var item = ref.item;
return [_c('td',[_c('router-link',{attrs:{"to":{
            name: 'PublicPlayroomDetail',
            params: { playroomId: item.playroomId },
          }}},[_vm._v(" "+_vm._s(item.playroomName)+" ")])],1)]}},{key:"bookingId",fn:function(ref){
          var item = ref.item;
return [_c('td',[_c('router-link',{attrs:{"to":{
            name: 'CustomerOrderDetail',
            params: {
              orderId: item.id,
            },
          }}},[_vm._v(" "+_vm._s(item.bookingId)+" ")])],1)]}},{key:"status",fn:function(ref){
          var item = ref.item;
return [_c('td',[_c('OrderStatus',{attrs:{"status":item.status}})],1)]}},{key:"bookingType",fn:function(ref){
          var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.$t('enums.bookingType.' + item.bookingType))+" ")])]}},{key:"start",fn:function(ref){
          var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.formatDate(item.start))+" ")])]}},{key:"end",fn:function(ref){
          var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.formatDate(item.end))+" ")])]}},{key:"hostName",fn:function(ref){
          var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.ownerDisplayName)+" ")])]}},{key:"amount",fn:function(ref){
          var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.formatCurrency(item.amount))+" ")])]}},{key:"rating",fn:function(ref){
          var item = ref.item;
return [_c('td',[(!item.rating && item.status == 'Completed')?_c('CButton',{directives:[{name:"t",rawName:"v-t",value:('pages.customer.orders.customerOrderIndex.rateNow'),expression:"'pages.customer.orders.customerOrderIndex.rateNow'"}],attrs:{"color":"light"},on:{"click":function($event){return _vm.openSetRatingPopup(item)}}}):_vm._e(),(item.rating)?_c('CButton',{attrs:{"color":"light"},on:{"click":function($event){return _vm.openShowRatingPopup(item)}}},[(item.rating)?_c('Rating',{attrs:{"rating":item}}):_vm._e()],1):_vm._e()],1)]}},{key:"actions",fn:function(ref){
          var index = ref.index;
          var item = ref.item;
return [_c('td',[_c('CDropdown',{attrs:{"color":"primary","toggler-text":"","data-cy":"action-button"}},[_c('CDropdownItem',{directives:[{name:"t",rawName:"v-t",value:('pages.customer.orders.customerOrderIndex.customerOrderDetail'),expression:"'pages.customer.orders.customerOrderIndex.customerOrderDetail'"}],attrs:{"to":{
              name: 'CustomerOrderDetail',
              params: {
                orderId: item.id,
              },
            }}}),(_vm.canBeDeleted(item))?_c('CDropdownItem',{directives:[{name:"t",rawName:"v-t",value:('pages.customer.orders.customerOrderIndex.deleteBooking'),expression:"'pages.customer.orders.customerOrderIndex.deleteBooking'"}],on:{"click":function($event){return _vm.openDeleteModal(index)}}}):_vm._e(),_c('CDropdownItem',{directives:[{name:"t",rawName:"v-t",value:('pages.customer.orders.customerOrderIndex.contactSupport'),expression:"'pages.customer.orders.customerOrderIndex.contactSupport'"}],on:{"click":function($event){return _vm.openSupportModal()}}})],1)],1)]}}])}),_c('CPagination',{attrs:{"pages":_vm.pages,"activePage":_vm.currentPage},on:{"update:activePage":function($event){_vm.currentPage=$event},"update:active-page":function($event){_vm.currentPage=$event}}}),_c('DeleteConfirmationModal',{attrs:{"show":_vm.showDeleteModal,"message":_vm.$t('pages.customer.orders.customerOrderIndex.deleteMsg')},on:{"delete":_vm.internalOnDelete,"close":_vm.closeDeleteModal}}),_c('SupportModal',{attrs:{"show":_vm.showSupportModal},on:{"onClose":function($event){_vm.showSupportModal = false}}}),_c('SetRatingModel',{attrs:{"show":_vm.showSetRatingModal,"playroomId":_vm.playroomId,"orderId":_vm.orderId,"title":_vm.ratingTitle,"bookingSlot":_vm.bookingSlot},on:{"onClose":function($event){_vm.showSetRatingModal = false},"onSubmit":function($event){return _vm.refreshData()}}}),_c('ShowRatingModel',{attrs:{"show":_vm.showShowRatingModal,"playroomId":_vm.playroomId,"orderId":_vm.orderId,"title":_vm.ratingTitle,"bookingSlot":_vm.bookingSlot},on:{"onClose":function($event){_vm.showShowRatingModal = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }